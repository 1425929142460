import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "path": "/media-art-me",
  "date": "2021-03-21",
  "title": "Previous Media Art Work",
  "tags": ["mediaart", "2021"],
  "excerpt": "A place for previous work"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This post is a place for previous media art work of mine.`}</p>
    <h2>{`Deep Learning Kubrick`}</h2>
    <p>{`Making use of image-recognition software, Deep Learning Kubrick explores the idea of AI trying to make sense of stories and fictions; in this case, snippets from classics by film director Stanley Kubrick. Taking stills from these snippets at four-second intervals, the algorithm analyses and tries to describe what it sees in the image. Without any knowledge of what happened before and what will happen after each still, and without any cultural context of cinema, let alone of a Kubrick film, an alternative narrative emerges out of its descriptions. As humanity comes to terms with the existence of other forms of intelligence, our ability (and evolutionary advantage) to understand and believe in fiction might be the next frontier for AI to master.`}</p>
    <p>{`Deep Learning Kubrick has been shortlisted for the Tate IK Prize - Artificial Intelligence and has been exhibited at Ars Electronica.`}</p>
    <iframe src="https://player.vimeo.com/video/129421029" width="100%" height="400px" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
    <h2>{`Ways of Seeing`}</h2>
    <p>{`Ways of Seeing is an immersive installation in which the role of various machines is taken on in a contemplative way. The world is measured through the eyes of the imaging machines and their posthuman perspectives and new worldviews are thus made divisible. Wandering through the city as a smartphone constantly looking for facial features, the world appears as a flickering, triangulated accumulation of vectors. Constructing the environment as a giant point cloud, an autonomous car attempts to navigate through the streets. At night, a drone detects the body heat of the city’s inhabitants with its infrared sensors. This is a game environment to engender forms of non-human empathy and understanding of how machines construct the world. By clicking on different subject positions you are able to switch between different cameras, different algorithms and ultimately different ways of seeing. Clicking on different points of view becomes an interplay of cameras, technologies and their ways of looking at things. In particular, the work shows us the different algorithms and ultimately the different ways of seeing that change from the non-human perception to the seasoned, mediated experience.`}</p>
    <p>{`Ways of Seeing has been exhibited at SCI-Arc, Los Angeles, and State Gallery, Berlin.`}</p>
    <iframe src="https://player.vimeo.com/video/526897823" width="100%" height="400px" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      